import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/layout";
import InfoPage from "../components/Page/info-page";

const Gowns = ({ data }) => (
    <Layout page={"gowns"}>
        <InfoPage {...data.contentfulInfoPage} />
    </Layout>
);

export default Gowns;

export const query = graphql`
  query {
    contentfulInfoPage(name: {eq: "gowns"}) {
      id
      name
      heading
      body {
        childMarkdownRemark {
            html
        }
      }
      showSafetyLink
      updatedAt
    }
  }
`;